<template>
  <v-container class="mt-6">
    <v-card max-width="800" elevation="12" class="mx-auto">
      <v-card-title>Subscription Error</v-card-title
      ><v-card-text>
        Your Risk Score subscription appears to be inactive. Please contact support for
        assistance:
        <a href="mailto:support@riskscore.us">Risk Score Support</a></v-card-text
      ></v-card
    >
  </v-container>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped></style>
